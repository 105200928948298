import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import cn from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AuButton from '@au/core/lib/components/elements/AuButton';
import AuComponent from '@au/core/lib/components/elements/AuComponent';
import ProcessingButton from '@au/core/lib/components/elements/ProcessingButton';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuToggle from '@au/core/lib/components/elements/AuToggle';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';
import SearchBox from '@au/core/lib/components/elements/SearchBox';
import MultiActionButton from '@au/core/lib/components/elements/MultiActionButton';
import DynamicDataTable from '@au/core/lib/components/elements/dynamic-data-table';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import {
  RESPONSIVE_TABLE_DEFAULT_WIDTH,
  RESPONSIVE_TABLE_DEFAULT_FLEX_GROW, RESPONSIVE_TABLE_DEFAULT_FLEX_SHRINK,
  RESPONSIVE_TABLE_DEFAULT_PAGE_SIZE, RESPONSIVE_TABLE_PAGES_TO_PRELOAD,
  RESPONSIVE_TABLE_PAGES_TO_CONTINUE
} from '../../constants';
import { entityEvent, SERVICES_PATH } from '../../constants';
import { history as browserHistory } from '../../history';
import { loadJoinedResources } from '../../utils/api';
import {
  getParentEntityAwarePath,
  getEntityReplicateUrl,
  skipDisplay,
  orderByDisplay,
  shouldHideContent
} from '../../utils/entity';
import Breadcrumbs from './Breadcrumbs';
import Banner from '../Banner';
import FiltersCountButton from '../FiltersCountButton';
import TimezoneSelector from '../TimezoneSelector';
import ColumnSelector from '../ColumnSelector';
import { formatMessage } from '../../utils/reactIntl';
import { parseCallable } from '../../utils/parse';
import customFormatters from '../../utils/formatters';
import customProcessors from '../../utils/processors';
import customSortFns from '../../utils/customSortFns';
import { wrapActionWithTracking } from '../../utils/analyticsHelpers';
import { filtersToQueryParams } from '../utils/filters';
import { PAGE_SCHEMA_CREATE, PAGE_SCHEMA_BACK, PAGE_SCHEMA_LOGO } from '../MobilePageHeader';
import SidebarFilters from '../../containers/SidebarFilters';
import EntityReplicateDialog from '../../containers/EntityReplicateDialog';
import ListStatus from '../ListStatus';
import defer from '../../utils/defer';
import { setPageTitle } from "../utils/pageTitle";
import SidebarSubviews from "../SidebarSubview";
import SuccessDialog from './SuccessDialog';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import styles from '../../css/components/entity_list.module.scss';

export default class List extends AuComponent {

  static propTypes = {
    entities: IPT.map.isRequired,
    entityDef: T.object.isRequired,
    endpoint: T.object.isRequired,
    pageSize: T.number,
    initPagesToLoad: T.number,
    actions: T.shape({
      setPageTitle: T.func.isRequired,
      setPageSchema: T.func.isRequired,
      openEntityPage: T.func.isRequired,
      saveUserData: T.func.isRequired,
      listEntitiesSuccess: T.func.isRequired,
      deleteEntitySuccess: T.func.isRequired
    }),
    sidebarExpanded: T.bool,
    searchText: T.string,
    searchTextPath: T.array,
    confirmEntityIdDelete: T.string
  };

  static defaultProps = {
    formatters: {},
    pageSize: RESPONSIVE_TABLE_DEFAULT_PAGE_SIZE,
    initPagesToLoad: RESPONSIVE_TABLE_PAGES_TO_PRELOAD,
    sidebarExpanded: true
  }

  _initFilters = defer();
  _initParentEntity = defer();

  /*
    this id is used to make sure that the data we received belongs to the most
    recent request that was sent.
   */
  _requestCorrelationId = 0;

  error = {
    search: {
      displayId: `au.searchList.error`,
      values: { click: { displayId: 'au.searchList.tryAgain', onClick: this.fetchPages.bind(this) } }
    },
    filters: {
      displayId: `au.searchList.filterError`,
      values: {
        click: { displayId: 'au.searchList.tryAgain', onClick: this.applyFilters.bind(this) },
        reset: { displayId: 'au.searchList.reset', onClick: this.resetFilters.bind(this) }
      }
    }
  }

  actionsRef = React.createRef();
  listRef = React.createRef();
  _leftSidebarRef = React.createRef();

  constructor(props) {
    super(props);

    const hasData = props.entities.size > 0;
    const { searchText } = this.props;

    const isMobile = props.screenWidth !== 'desktop';

    this.state = {
      errorStatusCode: null,
      errorStatusMessage: null,
      entity: {},
      searchText: searchText,
      timezone: props.timezone,
      confirmEntityIdDelete: null,
      fetching: !hasData,
      updating: false,
      ready: false,
      allDataFetched: false,
      disabled: false,
      filterQueryParams: {},
      filtersCount: 0,
      sidebarExpanded: this.props.sidebarExpanded,
      subviewSidebarOpen: !isMobile,
      cancel: false,
      showReplicateDialog: false,
      showDeleteSuccessDialog: false,
      columns: [],
      columnDefs: [],
      test: false,
      changing: false
    };

    this.baseUrl = props.match.url.split('/').slice(0, -1).join('/');

    if (hasData) {
      this.tableDef = this.generateTableDef();
    }

    if (props.parentEntity) {
      this.parentUrl = this.baseUrl.split('/').slice(0, -1).join('/');
    }
  }

  componentDidMount() {
    createResponseAlertMessage('clearEvents')
    window.addEventListener(entityEvent.CREATE_BTN_CLICK, this.onCreateBtnClick);
    this.initialize();
  }

  componentWillUnmount() {
    const { actions, searchTextPath } = this.props;
    // save search text
    actions.saveUserData(this.state.searchText, searchTextPath);

    window.removeEventListener(entityEvent.CREATE_BTN_CLICK, this.onCreateBtnClick);
  }

  async initialize() {
    const { entityDef, resources, actions, breadcrumbs } = this.props;

    setPageTitle(actions.setPageTitle, breadcrumbs);
    actions.setPageSchema(
      !entityDef.readonly ? PAGE_SCHEMA_CREATE : PAGE_SCHEMA_BACK
    );

    // `source` and `join` attributes are treated in the same way
    const sources = (
      Object.values(entityDef.attributes)
        .filter(attr => attr.source || attr.sources || attr.join)
        .reduce((acc, attr) => {
          attr.source && attr.source.useOnList && acc.push(attr.source);
          attr.join && acc.push(attr.join);
          attr.sources && attr.sources.forEach(source => source.useOnList && acc.push(source));
          return acc;
        }, [])
    );
    /*
      sources - contains service/entity definitions from where data needs to be loaded
      resources - contains actual data that was already loaded (based on `sources`)
     */
    loadJoinedResources(sources, resources, actions)
      .catch(this.onError)
      // make sure we have all parent entities loaded
      .then(this.fetchParentEntities)
      .catch(err => { this.onError(err); })
      .then(() => {
        // Need to wait until parentEntity.entity appears in props
        return this._initParentEntity.promise;
      })
      .then(() => {
        if (entityDef.sidebarFilters) {
          // halts progress until filters are initialized
          return this._initFilters.promise;
        }
        return Promise.resolve();
      })
      // we'll try to preload N pages and if all the data was loaded - enable searchBox
      .then(() => this.fetchInitialPages()) // overriding response from previous promise to ensure default pagesToLoad is applied
      .catch(createResponseAlertMessage)
      .then(() => {
        if (!this.state.ready) {
          this.tableDef = this.generateTableDef();
          this.setState({ ready: true });
        }
      });
  }

  componentDidUpdate(prevProps) {
    const { parentEntity, actions, screenWidth, breadcrumbs } = this.props;
    const isMobile = screenWidth !== "desktop";

    if (parentEntity?.entity && this._initParentEntity.isPending()) {
      this._initParentEntity.resolve();
    }

    if (prevProps && prevProps.screenWidth === undefined) {
      this.setState({ subviewSidebarOpen: !isMobile });
    }

    if (prevProps && prevProps.breadcrumbs !== breadcrumbs) {
      setPageTitle(actions.setPageTitle, breadcrumbs);
    }

    if (screenWidth === 'tabletPortrait') {
      actions.setPageSchema(PAGE_SCHEMA_LOGO);
    } else {
      actions.setPageSchema(PAGE_SCHEMA_BACK);
    }
  }

  fetchParentEntity = this.fetchParentEntity.bind(this);
  async fetchParentEntity(parentEntity) {
    const { actions } = this.props;
    // If we don't have our parent entity, fetch first for breadcrumb data and similar
    if (parentEntity && !parentEntity.entity?.size && parentEntity.endpoint.actions.includes('get')) {
      if (parentEntity.parentEntity) {
        await this.fetchParentEntity(parentEntity.parentEntity);
      }
      return parentEntity.endpoint.get(parentEntity.entityId, parentEntity.entityDef.queryParams).then(resp => {
        // endpoint.get will put the data to the store using endpoint-based path
        // we need to [also] put the data using relative, parent-aware path to make it available
        actions.getEntitySuccess({
          path: getParentEntityAwarePath(parentEntity),
          data: resp.data,
          pkField: parentEntity.entityDef.pkField
        });
      });
    }
    this._initParentEntity.resolve();
    return Promise.resolve();
  }

  fetchParentEntities = this.fetchParentEntities.bind(this);
  fetchParentEntities() {
    const { parentEntity } = this.props;
    // fetch parent entities recursively
    return this.fetchParentEntity(parentEntity);
  }

  // Will reset the pages iterator and cause the next store operation
  // This will reset page iteration and the next page will be the first which
  // will replace the current maps
  resetPages() {
    /*
      increasing correlation Id, so when we get the response we can compare
      current id and the one we saved before sending the request.
      If they don't match - ignore the (outdated) results and wait for the
      upcoming response.
     */
    ++this._requestCorrelationId;
    this._listIterator = undefined;
    this.setState({ allDataFetched: false });
  }

  // Returns an async iterator that will resume on each call.
  // Does not support concurrent calls.
  async *pages() {
    const cid = this._requestCorrelationId;
    if (!this._listIterator) {
      try {
        this._listIterator = await this.list();
      }
      catch (err) {
        createResponseAlertMessage(err);
        this.setState({ updating: false, fetching: false, showError: true, errorType: 'search', allDataFetched: true });
        return;
      }
    }
    else {
      // skip first response since it always starts with itself
      await this._listIterator.next();
    }
    for await (let resp of this._listIterator) { // eslint-disable-line semi
      this._listIterator = resp[Symbol.asyncIterator]();
      // if the correlation Id has changed - don't update the data, end the iterator
      if (cid !== this._requestCorrelationId) {
        return;
      }
      this.onListSuccess(resp);
      if (!resp.hasNextPage) {
        this.setState({ allDataFetched: true });
      }
      yield resp;
    }
  }

  async nextPage() {
    for await (let resp of await this.pages()) { // eslint-disable-line semi
      return resp;
    }
  }

  fetchInitialPages = this.fetchInitialPages.bind(this);
  async fetchInitialPages(pagesToLoad = this.props.initPagesToLoad) {
    this.setState({ fetching: true });

    let pagesLoaded = 0;
    for await (let _ of await this.pages()) { // eslint-disable-line semi, no-unused-vars
      if (++pagesLoaded >= pagesToLoad || this.state.cancel) {
        break;
      }
    }
    this.setState({ fetching: false });
  }

  onListSuccess = this.onListSuccess.bind(this);
  onListSuccess(resp) {
    const { parentEntity, match, actions, entityDef } = this.props;
    const { entityAlias } = match.params;

    if (parentEntity && entityDef.parentEndpoint) {
      // entity's regular success action is already called in api.js
      actions.listEntitiesSuccess({
        path: getParentEntityAwarePath(parentEntity, entityAlias),
        // "list" endpoint will expose items, but "inspect", "search", etc may not
        data: resp.items || resp.data.items,
        pkField: entityDef.pkField,
        replace: resp.isFirstPage
      });
    }
  }

  async list() {
    const { endpoint, pageSize, parentEntity, entityDef } = this.props;

    // Special handling for entities that have different service endpoints
    const extraParams = {};
    if (parentEntity && entityDef.parentEndpoint) {
      if (entityDef.type === 'permission') {
        return endpoint.inspect({
          objectAui: (parentEntity.entityDef.arn ? parentEntity.entityDef.arn + '/' : '') + parentEntity.entityId,
          pageSize
        });
      } else {
        // Pick up any extra params for a list specified in the service yamls
        // e.g. - vehicle/groups/list needs a vehicleId or VIN
        const { queryParams: parentEndpointQueryParams } = entityDef.parentEndpoint;
        if (parentEndpointQueryParams) {
          Object.keys(parentEndpointQueryParams).forEach(param =>
            extraParams[param] = parentEntity.entity.getIn(parentEndpointQueryParams[param].prop.split("."))
          ); 
        }
      }
    }

    const queryParams = { pageSize, ...entityDef.queryParams, ...extraParams, ...this.state.filterQueryParams };
    return endpoint.list(queryParams);
  }

  loadNextPage = this.loadNextPage.bind(this);
  loadNextPage() {
    this.setState({ fetching: true });
    return this.nextPage().catch(this.onError)
      .then(() => this.setState({ fetching: false }));
  }

  onCreateBtnClick = this.onCreateBtnClick.bind(this);
  onCreateBtnClick() {
    //FIXME - MOVE TO linkHelper
    browserHistory.push(this.baseUrl + '/create');
  }

  handleMouseEnter = this.handleMouseEnter.bind(this);
  handleMouseEnter() {
    // override
  }

  handleMouseLeave = this.handleMouseLeave.bind(this);
  handleMouseLeave() {
    // override
  }

  onError = this.onError.bind(this);
  onError(respOrExcep) {
    this.setState({ fetching: false, showError: true });

    if (!respOrExcep) {
      // can occur if network connection is unavailable
      return;
    }

    createResponseAlertMessage(respOrExcep)
  }

  addTracking = this.addTracking.bind(this);
  addTracking(formatter) {
    const { entityAlias } = this.props.match.params;
    return {
      ...formatter,
      args: {
        ...formatter.args,
        tracking: {
          page: `${entityAlias}List`
        }
      }
    };
  }

  checkDisplay(attr) {
    return shouldHideContent(attr) || skipDisplay(attr, 'list');
  }

  getFormatters(property, attr) {
    const { entityDef } = this.props;
    let formatters = [];

    if (!attr.formatters) {
      if (property === entityDef.pkField) {
        formatters = [{
          func: 'entityLink',
          args: {
            idProperty: entityDef.pkField,
            url: this.baseUrl,
            entityType: entityDef.type,
            entityAlias: this.props.match.params.entityAlias,
            serviceAlias: this.props.serviceAlias,
            viewInPopout: entityDef.viewInPopout
          }
        }];
      } else if ('ref' in attr) {
        formatters = [{
          func: 'entityLink',
          args: {
            idProperty: attr.ref.idProperty || property,
            url: attr.ref.service && attr.ref.entity
              ? `${SERVICES_PATH}/${attr.ref.service}/${attr.ref.entity}`
              : `${this.baseUrl}`,
            entityType: entityDef.type,
            entityAlias: this.props.match.params.entityAlias,
            serviceAlias: this.props.serviceAlias,
            viewInPopout: entityDef.viewInPopout
          }
        }];
      }
    } else {
      formatters = parseCallable(attr.formatters);
      formatters.forEach(formatter => {
        if (!formatter.args) {
          formatter.args = {};
        }
        formatter.args.resources = this.props.resources;
      });
    }

    return formatters.map(this.addTracking);
  }

  generateTableDef() {
    const { entityDef } = this.props;

    const getColumnDefs = attrs => {
      const columnDefs = [];
      if (attrs) {
        for (let [property, attr] of orderByDisplay(attrs, 'list')) {
          let labelId = attr.labelId || `au.entity.attr.${property}`;
          let labelValues = attr.labelHintId && { detail: formatMessage({ id: attr.labelHintId }, { hint: (text) => <span className={styles.hint}>{text}</span> }) };

          columnDefs.push({
            property,
            ref: attr.ref,
            label: formatMessage({ id: labelId }, labelValues),
            processors: parseCallable(attr.processors),
            formatters: this.getFormatters(property, attr),
            sortable: Boolean(attr.sortable),
            searchable: Boolean(attr.searchable),
            display: !this.checkDisplay(attr),
            sortFn: attr.sortFn,
            style: {
              width: RESPONSIVE_TABLE_DEFAULT_WIDTH,
              flexGrow: RESPONSIVE_TABLE_DEFAULT_FLEX_GROW,
              flexShrink: RESPONSIVE_TABLE_DEFAULT_FLEX_SHRINK,
              ...attr.style,
            }
          });
        }
      }

      if (!entityDef.readonly) {
        columnDefs.push({
          property: 'actions',
          label: formatMessage({ id: 'au.entity.title.actions' }, ''),
          formatters: [{ func: 'actionCell' }],
          display: true,
          style: {
            width: 72,
            flexGrow: 0,
            flexShrink: 0
          },
          className: styles.actions_column,
          headerClassName: styles.header_actions_column
        });
      }

      return columnDefs;
    };

    let columnDefs;
    if (localStorage.getItem(`${entityDef.type}-columns`) && localStorage.getItem(`${entityDef.type}-columns`) !== '') {
      columnDefs = getColumnDefs(JSON.parse(localStorage.getItem(`${entityDef.type}-columns`)));
    }
    else {
      columnDefs = getColumnDefs(entityDef.attributes);
    }
    // In order to properly apply the formatters for each attribute, the const getColumnDefs() needs to be run with each load of the page. Local storage should be set with an object like
    // the original yaml structure that can be used as an input for the getColumnDefs() function while the state should contain that object and the results of getColumnDefs() which is an 
    //array as well. Both of these must be updated simultaneously
    this.setState({columns: columnDefs, columnDefs: JSON.parse(localStorage.getItem(`${entityDef.type}-columns`)) || entityDef.attributes});

    return {
      tableId: entityDef.type,
      tablePkField: entityDef.pkField,
      sort: entityDef.defaultSort,
      searchboxPlaceholder: formatMessage(
        { id: 'au.entity.action.search' },
        { entityName: formatMessage({ id: `au.entity.name.${entityDef.type}` }) }
      ),
      columnDefs: columnDefs
    };
  }

  onSwitchBtnClick = this.onSwitchBtnClick.bind(this);
  onSwitchBtnClick(id, prop) {
    const { endpoint } = this.props;
    const entity = id && this.props.entities.get(id);

    if (entity) {
      endpoint.patch(id, { [prop]: !entity.get(prop) });
    }
  }

  onViewBtnClick = this.onViewBtnClick.bind(this);
  onViewBtnClick(id) {
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: this.baseUrl + `/${id}/view`,
      state: { prevUrl: this.props.match.url }
    });
  }

  onOverviewBtnClick = this.onOverviewBtnClick.bind(this);
  onOverviewBtnClick(id) {
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: this.baseUrl + `/${id}/overview`,
      state: { prevUrl: this.props.match.url }
    });
  }

  onEditBtnClick = this.onEditBtnClick.bind(this);
  onEditBtnClick(id) {
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: this.baseUrl + `/${id}/edit`,
      state: { prevUrl: this.props.match.url }
    });
  }

  onDeleteBtnClick = this.onDeleteBtnClick.bind(this);
  onDeleteBtnClick(id) {
    if (this.rowRef) {
      this.rowRef.classList.remove(styles.row_border);
    }
    // id needs to be decoded before we can do a lookup
    const entity = id && this.props.entities.get(decodeURIComponent(id));
    if (entity) {
      this.setState({ entity: entity.toJS(), confirmEntityIdDelete: id, disabled: true });
    }
  }

  onReplicateBtnClick = this.onReplicateBtnClick.bind(this);
  onReplicateBtnClick(id) {
    const entity = id && this.props.entities.get(decodeURIComponent(id));
    if (entity) {
      this.setState({ showReplicateDialog: true, entity });
    }
  }

  initializeFilters = this.initializeFilters.bind(this);
  initializeFilters(filters, filtersCount) {
    const { entityDef } = this.props;
    if (entityDef.sidebarFilters) {
      this.setState({
        filterQueryParams: filtersToQueryParams(filters),
        filtersCount
      }, this._initFilters.resolve);
    } else {
      this._initFilters.resolve();
    }
  }
  handleFiltersChange = debounce(this.handleFiltersChange, 150).bind(this);
  handleFiltersChange(filters, filtersCount) {
    const filterQueryParams = filtersToQueryParams(filters);
    this.setState({ filterQueryParams, filtersCount }, this.applyFilters);
  }

  applyFilters = this.applyFilters.bind(this);
  applyFilters() {
    this.setState({ updating: true, cancel: false, showError: false }, () => {
      this.resetPages();
      const cid = this._requestCorrelationId;
      this.fetchInitialPages().then(
        () => {
          if (cid === this._requestCorrelationId) {
            this.setState({ updating: false });
            // get React Virtualized Grid component class name
            const { className } = this.listRef?.current?.listRef?.Grid?.props || {};
            const listEl = document.querySelector(`.${className}`);
            if (listEl) {
              // reset scroll position
              listEl.scrollTop = 0;
            }
          }
        },
        error => this.setState(() => {
          if (cid === this._requestCorrelationId) {
            this.setState({ updating: false, fetching: false, showError: true, errorType: 'filters' });
            createResponseAlertMessage(error);
            // TODO we need to add a message inside the table with Reset button
            this.onListSuccess({ items: [], isFirstPage: true });
          } else {
            // log error message
            AuAnalytics.trackException({
              description: error.toString(),
              fatal: false
            });
          }
        })
      );
    });
  }

  hideConfirmationMessage = this.hideConfirmationMessage.bind(this);
  hideConfirmationMessage() {
    this.setState({ confirmEntityIdDelete: null, disabled: false });
    if (this.rowRef) {
      this.rowRef.classList.remove(styles.row_border);
      this.contentRef.classList.remove(styles.hover_margin);
    }
  }

  deleteEntity = this.deleteEntity.bind(this);
  deleteEntity() {
    const { endpoint, entityDef, parentEntity, match, actions } = this.props;
    const pkField = this.tableDef.tablePkField;
    const entityId = get(this.state.entity, pkField);

    if (entityId) {
      return endpoint.delete(entityId).then(() => {
        const { entityAlias } = match.params;
        if (parentEntity && entityDef.type === 'permission') {
          const path = getParentEntityAwarePath(parentEntity, entityAlias);
          actions.deleteEntitySuccess({ path: [...path, entityId.toString()] });
        }
        this.setState({ showDeleteSuccessDialog: true });
      }, createResponseAlertMessage);
    }
  }

  onTimeZoneChange = this.onTimeZoneChange.bind(this);
  onTimeZoneChange(timezone) {
    this.setState({ timezone });
  }

  onSearchTextChange = this.onSearchTextChange.bind(this);
  onSearchTextChange(searchText) {
    searchText = searchText.trim();
    return new Promise(res => {
      if (searchText !== this.state.searchText) {
        this.setState({ searchText }, () => res(true));
      } else {
        res(false);
      }
    });
  }

  toggleSidebar = this.toggleSidebar.bind(this);
  toggleSidebar() {
    const { screenWidth } = this.props;

    this.setState(prevState => {
      const isMobile = screenWidth !== 'desktop';
      if (isMobile && !prevState.sidebarExpanded) {
        return { subviewSidebarOpen: false, sidebarExpanded: !prevState.sidebarExpanded };
      }
      return { sidebarExpanded: !prevState.sidebarExpanded };
    });
  }

  setSubviewSidebar = this.setSubviewSidebar.bind(this);
  setSubviewSidebar(value) {
    const { screenWidth } = this.props;
    const isMobile = screenWidth !== 'desktop';

    this.setState({ subviewSidebarOpen: value });

    if (isMobile && value) {
      this.setState({ sidebarExpanded: false });
    }
  }

  getNavLinks() {
    const { parentEntity } = this.props;
    const to = this.baseUrl.split('/');
    const navLinks = [];

    if (parentEntity && parentEntity.entityDef.subviews) {
      for (let [subViewAlias, subViewDef] of Object.entries(parentEntity.entityDef.subviews)) {
        if (skipDisplay(subViewDef, 'list')) continue;
        if (shouldHideContent(subViewDef)) continue;
        if (subViewDef.display === false) {
          continue;
        }
        //Is this a non-List custom View? Construct a custom action link
        if (subViewDef.action) {
          navLinks.push({
            labelId: subViewDef.labelId || `au.entity.title.${parentEntity.entityDef.type}.${subViewDef.action}`,
            destination: `${to.slice(0, -1).join('/')}/${subViewDef.action}`,
            isEndOfSection: subViewDef.action === "view"
          });
        }
        else { //assume is a sub-entity list
          navLinks.push({
            labelId: subViewDef.labelId || `au.entity.title.${subViewDef.type}`,
            destination: `${to.slice(0, -1).join('/')}/${subViewAlias}/list`
          });
        }
      }
    }

    return navLinks;
  }

  getCrumbs() {
    const { breadcrumbs, parentEntity } = this.props;
    return parentEntity ? breadcrumbs.slice(0, -1) : [...breadcrumbs];
  }

  getRowActions({ id, canWrite, canReplicate, permissions }) {
    const actions = [];
    const { entityAlias } = this.props.match.params;

    if (this.props.entityDef.overviewPage) {
      actions.push({ displayId: 'au.entity.a.overview', onClick: this.onOverviewBtnClick.bind(this, id) });

    } else {
      actions.push({ displayId: 'au.entity.viewDetails', onClick: this.onViewBtnClick.bind(this, id) });
    }
    if (canWrite && permissions.canEdit) {
      actions.push({ displayId: 'au.entity.edit', onClick: this.onEditBtnClick.bind(this, id) });
    }

    if (canReplicate) {
      actions.push({ displayId: 'au.entity.replicate', onClick: this.onReplicateBtnClick.bind(this, id) });
    }

    if (canWrite && permissions.canDelete) {
      actions.push({ displayId: 'au.entity.delete', onClick: this.onDeleteBtnClick.bind(this, id) });
    }

    return actions.map((mappedAction) =>
      wrapActionWithTracking(mappedAction, entityAlias, 'List')
    );
  }

  fetchPages = this.fetchPages.bind(this)
  fetchPages() {
    this.setState({ cancel: false, showError: false });
    this.fetchInitialPages(RESPONSIVE_TABLE_PAGES_TO_CONTINUE)
      .catch(createResponseAlertMessage);
  }

  cancel = this.cancel.bind(this)
  cancel() {
    this.setState({ cancel: true });
  }

  resetFilters() {
    this.setState({ showError: false });
    if (this.handleFiltersReset) {
      this.handleFiltersReset();
    }
  }

  registerResetFilters = this.registerResetFilters.bind(this);
  registerResetFilters(resetFilters) {
    this.handleFiltersReset = resetFilters;
  }

  getAddons() {
    const { entityDef, permissions } = this.props;
    const { fetching, allDataFetched, searchText, sidebarExpanded, showError, ready, updating, cancel } = this.state;
    const entities = this.getFilteredEntities();
    const { entityAlias, action } = this.props.match.params;
    const canWrite = !entityDef.readonly;

    let addons = [];

    if (entityDef.searchBox) {
      const disabled = entityDef.serverSearch ? false : !allDataFetched;
      const searchEnabled = Boolean(this.state.searchText?.trim());
      const serverSideSearch = entityDef.serverSearch;

      addons.push(
        <div className={styles.search} key={`${entityDef.type}_searchbox`}>
          <SearchBox
            disabled={disabled}
            disabledHint={formatMessage({ id: 'au.searchbox.disabledHint' })}
            placeholder={entityDef.placeholder}
            placeholderId={entityDef.placeholderId}
            onChange={this.onSearchTextChange}
            className={styles.searchbox}
            value={searchText}
            debounce={entityDef.serverSearch ? 500 : 250} // default value 100ms is not enough
            loading={fetching}
          />
          {ready && !updating && !searchEnabled && !showError && <ListStatus
            showLoadAllText={!serverSideSearch}
            allDataFetched={allDataFetched}
            count={entities.size}
            entityType={entityDef.type}
            fetching={fetching}
            cancel={cancel}
            onClick={fetching ? this.cancel : this.fetchPages}
            className={styles.search_box_status} />}
        </div>
      );
    }

    if (canWrite && permissions.canCreate) {
      addons.push(
        <div className={styles.buttons} key={`entity_buttons`}>
          <AuButton
            type="primary"
            displayString={formatMessage({ id: this.getTextIdForCreate() }, {
              entityName: formatMessage({ id: `au.entity.name.${entityDef.type}` })
            })}
            className={styles.create_btn}
            onClick={this.onCreateBtnClick}
            disabled={this.state.disabled}
            tracking={{
              action: entityAlias,
              page: `${entityAlias}${action.charAt(0).toUpperCase() + action.slice(1)}`
            }}
          />
        </div>
      );
    }

    if (entityDef.sidebarFilters && !sidebarExpanded) {
      addons.push(
        <div className={styles.sidebar_button} key="sidebar_filters_button">
          <FiltersCountButton
            count={this.state.filtersCount}
            onClick={this.toggleSidebar}
          />
        </div>
      );
    }

    return addons;
  }

  getBanner() {
    const { entityDef } = this.props;
    const isChildEntity = Boolean(this.props.parentEntity);

    if (isChildEntity && entityDef.type === 'permission') {
      return (
        <Banner type='warning' className={styles.banner}>
          <FormattedMessage
            id="au.permissions.obsoleteWarning"
            values={{
              v2Policies: (
                <Link to={this.baseUrl.split('/').slice(0, -1).join('/') + '/policies/list'}>
                  {formatMessage({ id: 'au.permissions.v2Policies' })}
                </Link>
              )
            }}
          />
        </Banner>
      );
    }

    return false;
  }

  getTextIdForCreate() {
    return 'au.entity.action.create';
  }

  getLeftHeader() {
    const { entityDef } = this.props;
    const isChildEntity = Boolean(this.props.parentEntity);
    const addOns = this.getAddons();
    return (
      <div className={styles.left_header}>
        <Breadcrumbs crumbs={this.getCrumbs()} />
        {!isChildEntity && addOns && addOns.length > 0 &&
          <div className={cn(styles.addons, { [styles.align_right]: entityDef.searchBox })}>
            {addOns}
          </div>}
      </div>
    );
  }

  getLeftAddons() {
    const leftAddons = [];
    const { columns} = this.state;

    if (this.props.entityDef.type !== 'commandManagement' && this.props.breadcrumbs?.length <= 1) {
      leftAddons.push(
        <ColumnSelector attributes={this.props.entityDef.attributes} columns={columns} handleChange={this.handleColumnChange} handleReorder={this.handleColumnReorder}/>
      )
    }

    leftAddons.push(
      <TimezoneSelector onChange={this.onTimeZoneChange} />
    )

    return leftAddons;
  }

  handleColumnChange = this.handleColumnChange.bind(this);
  handleColumnChange(field, action) {
    const { columns, columnDefs } = this.state;
    const { entityDef } = this.props;

    const newColumns = columns.map((col) => {
      if (col.property === 'actions') {
        return col;
      } 

      const prevDisplay = columnDefs[col.property].display;
      if (col.property === field) {
        if (action === 'delete') {
          col.display = false;
          columnDefs[col.property].display = {...prevDisplay, list: false};
        }
        else if (action === 'add') {
          col.display = true;
          columnDefs[col.property].display = {...prevDisplay, list: true};
        }
      }
      return col;
    })

    localStorage.setItem(`${entityDef.type}-columns`, JSON.stringify(columnDefs));
    this.setState((prevState) => ({columns: newColumns, changing: !prevState.changing, columnDefs: columnDefs}));
  }

  handleColumnReorder = this.handleColumnReorder.bind(this);
  handleColumnReorder(columns) {
    const { entityDef } = this.props;

    localStorage.setItem(`${entityDef.type}-columns`, JSON.stringify(columns));
    this.setState((prevState) => ({columns: columns, changing: !prevState.changing}));
  }

  renderSidebarFilters() {
    const { entityDef } = this.props;
    const { sidebarExpanded, timezone, ready } = this.state;

    if (!entityDef.sidebarFilters) {
      return false;
    }

    return (
      <SidebarFilters
        className={cn(styles.sidebar, { [styles.hidden]: !sidebarExpanded || !ready })}
        filtersDef={entityDef.sidebarFilters}
        onInit={this.initializeFilters}
        onClose={this.toggleSidebar}
        onChange={this.handleFiltersChange}
        registerResetHandler={this.registerResetFilters}
        timezone={timezone}
      />
    );
  }

  renderDialogs() {
    const { serviceAlias, entityDef, parentEntity, match } = this.props;
    const { entityAlias } = match.params;
    const { showDeleteSuccessDialog, entity } = this.state;
    const dialogs = [];

    if (entityDef.allowReplicate && this.state.showReplicateDialog) {
      dialogs.push(
        <EntityReplicateDialog
          key="entity_replicate_dialog"
          nextUrl={getEntityReplicateUrl(serviceAlias, entityAlias, parentEntity?.entityAlias)}
          prevUrl={match.url}
          entity={this.state.entity}
          noticeDisplayId={entityDef.replicateNoticeDisplayId}
          onBeforeReplicate={this.onBeforeReplicate}
          onCancel={() => this.setState({ showReplicateDialog: false })}
        />
      );
    }

    if (showDeleteSuccessDialog) {
      dialogs.push(
        <SuccessDialog
          key="entity_delete_success_dialog"
          nameDisplayId="au.entity.delete.success.name"
          nameValues={{
            entity: formatMessage({ id: `au.entity.name.${entityDef.type}` }),
            b: chunks => <strong>{chunks}</strong>,
            entityName: entity.displayName || entity.id
          }}
          messageId="au.entity.delete.success.message"
          messageValues={{ entity: <span className={styles.message}> {formatMessage({ id: `au.entity.name.${entityDef.type}` })}</span> }}
          onClose={() => { this.setState({ showDeleteSuccessDialog: false }); }}
        />
      );
    }

    return dialogs;
  }

  // All errors that occur on List pages should be handled with toast banner
  renderErrors() {
    const { errorStatusCode, errorStatusMessage } = this.state;

    if (errorStatusCode || errorStatusMessage) {
      return (
        createResponseAlertMessage({data: {
          message: errorStatusMessage,
          code: errorStatusCode
        }})
      );
    }

    return false;
  }

  //TODO - THIS SHOULD PROBABLY USE serviceDefs.js TO DEFINE AND DELEGATE TO A FILTER FUNCTION
  //     - RATHER THAN HAVE A SUBCLASS BE FORCED TO OVER-RIDE THIS FUNCTION
  getFilteredEntities() {
    return this.props.entities;
  }

  setRowRef = this.setRowRef.bind(this);
  setRowRef(ref) {
    if (ref) {
      this.rowRef = ref.closest('[role=row]');
      this.rowRef.classList.add(styles.row_border);
      this.contentRef = this.rowRef.firstChild;
      this.contentRef.classList.add(styles.hover_margin);
    }
  }

  onSearch = this.onSearch.bind(this);
  onSearch(searching) {
    this.setState({ searching: true, showError: false });
    searching.then(() => {
      this.setState({ searching: false });
    }, () => this.setState({ searching: false, showError: true, errorType: 'search' }));
  }

  getSearchText() {
    return this.state.searchText;
  }

  renderExtras() {
    return;
  }

  renderTableContent(entities) {
    const { entityDef, permissions, screenWidth } = this.props;
    const { fetching, timezone, allDataFetched, changing } = this.state;
    const canWrite = !entityDef.readonly;
    const canReplicate = canWrite && entityDef.allowReplicate;
    const tableDef = this.tableDef;

    return (
      <DynamicDataTable
        tablePkField={tableDef.tablePkField}
        sortBy={tableDef.sort?.columnId}
        sortDirection={tableDef.sort?.direction}
        customSort={customSortFns}
        columns={this.state.columns}
        data={entities}
        isFetchingNextPage={fetching}
        hasNextPage={!allDataFetched}
        fetchNextPage={this.loadNextPage}
        searchText={this.getSearchText()}
        timezone={timezone}
        processors={customProcessors}
        onScroll={this.handleScroll}
        changing={changing}
        formatters={Object.assign({}, customFormatters, {
          switchCell: ({ value, rowData, columnDef }) => {
            const pkField = tableDef.tablePkField;
            if (!rowData.has(pkField)) {
              return false;
            }

            const id = encodeURIComponent(rowData.get(pkField).toString());

            return (
              <AuToggle
                key={`client_toggle_${id}`}
                name={columnDef.property}
                checked={value}
                showLabel={true}
                onChange={this.onSwitchBtnClick.bind(this, id, columnDef.property)} />
            );
          },
          actionCell: ({ rowData }) => {
            const pkField = tableDef.tablePkField;

            if (!rowData.has(pkField)) {
              return false;
            }

            const id = encodeURIComponent(rowData.get(pkField).toString());
            const rowActions = this.getRowActions({ id, canWrite, canReplicate, permissions });
            const confirmDelete = this.state.confirmEntityIdDelete === id;
            const entityType = formatMessage({ id: `au.entity.name.${this.props.entityDef.type}` }).toLowerCase();

            return (
              <div className={styles.actions_wrapper} onMouseEnter={() => this.handleMouseEnter(rowData.get('type'), this.actionsRef)} onMouseLeave={() => this.handleMouseLeave(rowData.get('type'))} ref={this.actionsRef}>
                {<MultiActionButton actions={rowActions} screenWidth={screenWidth} disabled={this.state.disabled} />}
                {confirmDelete &&
                  <div className={styles.confirmation} ref={this.setRowRef}>
                    <div className={styles.message}>
                      <div className={styles.delete}>
                        <AutoIntl className={styles.msg} displayId="au.entity.delete.confirmation" values={{ entity: entityType }} />
                        <ProcessingButton
                          className={styles.proceed}
                          type="alert"
                          size="medium"
                          displayId="au.entity.proceed"
                          onClick={() => {
                            this.deleteEntity();
                            this.hideConfirmationMessage();
                          }}
                        />
                        <AuButton
                          type="plain"
                          size="medium"
                          className={styles.cancel}
                          displayId="au.entity.cancel"
                          onClick={this.hideConfirmationMessage}
                        />
                      </div>
                    </div>
                  </div>
                }
              </div>
            );
          }
        }, this.props.formatters)}
      />
    );
  }

  renderContent() {
    const { entityDef, screenWidth } = this.props;
    const isChildEntity = Boolean(this.props.parentEntity);
    const { ready, fetching, updating, showError, cancel, sidebarExpanded, subviewSidebarOpen } = this.state;
    const entities = this.getFilteredEntities();
    const cancelButton = <AutoIntl
      displayId={'au.searchList.cancel'}
      className={styles.cancel_button}
      onClick={this.cancel}
    />;
    const isMobile = screenWidth !== 'desktop';

    return (
      <div className={cn(styles.content, { [styles.popout]: updating || fetching })}>
        {(!ready || fetching) && !showError &&
          <div className={cn(styles.updater, {
            [styles.expanded_sidebar]: ready && sidebarExpanded && document.getElementById('sidebarFilters') !== null,
            [styles.expanded_subview]: subviewSidebarOpen && isChildEntity,
            [styles.expanded_sidebar_subview]: subviewSidebarOpen && sidebarExpanded && isChildEntity && document.getElementById('sidebarFilters') !== null
            })}>
            <LoadingIndicator className={styles.loader} displayId='au.noop' />
            <div className={styles.text}>
              { !cancel && <FormattedMessage
                id="au.searchList.loading.message"
                values={{
                  value: entities.size,
                  count: <div className={styles.count}><FormattedNumber value={entities.size} /></div>,
                  entityName: ( // single vs plural form
                    formatMessage({ id: `au.entity.${entities.size === 1 ? 'name': 'title'}.${entityDef.type}` })
                  ),
                  cancelButton
                }}
              /> }
              { cancel && <AutoIntl displayId="au.searchList.cancel.inProgress"/>}
            </div>
          </div>}
        <div className={styles.content_inner}>
          {isChildEntity &&
            <SidebarSubviews
              navLinks={this.getNavLinks()}
              portalRef={this._leftSidebarRef}
              tableLoaded={ready}
              open={this.state.subviewSidebarOpen}
              setOpen={this.setSubviewSidebar}
              isMobile={isMobile}
            />
          }
          <div className={styles.table_container}>
            {this.getBanner()}
            {this.renderExtras()}
            <div className={cn(styles.table_topper, {[styles.hidden]: !ready })}>
              {this.getLeftAddons()}
            </div>
            <div className={styles.table_filter_container}>
              {ready && this.renderTableContent(entities)}
              {this.renderSidebarFilters()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  containerSubviewStyle(isChildEntity, isOpen) {
    if (isChildEntity && isOpen) {
      return styles.subview_open;
    }
    else if (isChildEntity) {
      return styles.subview_closed;
    }
    return undefined;
  }

  render() {
    const { subviewSidebarOpen } = this.state;
    const { entityDef } = this.props;
    const isChildEntity = Boolean(this.props.parentEntity);
    const addOns = this.getAddons();

    return (
      <>
        <div ref={this._leftSidebarRef} />
        <div className={cn(styles.container, this.containerSubviewStyle(isChildEntity, subviewSidebarOpen))}>
          {isChildEntity &&
            <div className={styles.header}>
              <div className={styles.breadcrumbs}>
                <Breadcrumbs crumbs={this.getCrumbs()} />
                {addOns && addOns.length > 0 &&
                  <div className={cn(styles.addons, { [styles.align_right]: entityDef.searchBox })}>
                    {addOns}
                  </div>
                }
              </div>
            </div>
          }
          <div className={cn({ [styles.header]: !isChildEntity })}>
            {!isChildEntity && this.getLeftHeader()}
          </div>
          <div className="o-wrapper">
            {this.renderContent()}
          </div>
          {this.renderDialogs()}
        </div>
      </>
    );
  }

}
